<template>
  <div class="personality-test" >
    <div class="personality-test__title mb-4">
      
    </div>
    <div class="personality-test__description">
      Выберите интересующие вас терапевтические области.
      Темы помогут настроить вашу ленту с материалами
    </div>
    <div class="personality-test__nosologys" data-id="helperNos">
      <div
        class="personality-test__nosology"
        :class="{
          'personality-test__nosology_active': currentNosologys.includes(
            nosology.id
          ),
        }"
        v-for="nosology in filteredNosologys"
        :key="nosology.id"
        @click="choiceItem(nosology.id, nosology.title)"
      >
        <div
          class="personality-test__nosology-delete"
          v-if="currentNosologys.includes(nosology.id)"
        >
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.75 1.75L6.25 6.25"
              stroke="white"
              stroke-linecap="round"
            />
            <path
              d="M6.25 1.75L1.75 6.25"
              stroke="white"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <div class="personality-test__nosology-image-container">
          <img
            v-if="formattedImageList(nosology, 'list') !== 'undefined'"
            class="personality-test__nosology-image"
            :src="formattedImageList(nosology, 'list')"
          />
        </div>
        <div class="personality-test__nosology-title">
          {{ nosology.title }}
        </div>
      </div>
    </div>
    <div class="personality-test__disease mt-6" v-if="currentNosologys.length || showDiseases">
      <div class="personality-test__title mb-4">
        
      </div>
      <div class="personality-test__description">
        Выберите интересующие вас заболевания.
        Темы помогут настроить вашу ленту с материалами
      </div>
      <Select
      data-id="helperDis"
        v-model="disease"
        :items="diseaseList.formattedList"
        :multiple="true"
        :placeholder="'Выберите заболевания'"
        class="mb-4 personality-test__select"
        @optionTriggered="changeDisease"
      >
        <template #list-header>
          <div class="button button_small button_gray mx-4 mb-4 mt-2" @click="selectAll">{{diseaseList.formattedList.length === disease.length ? 'Снять все' : 'Выбрать все'}}</div>
        </template>
      </Select>
      <div
        class="filter__current-filter"
        v-if="currentDiseases && currentDiseases.length"
      >
        <div
          class="filter__current-filter-item"
          v-for="(item, ind) in currentDiseases"
          :key="ind"
        >
          <span v-html="item.label"></span>
          <span
            @click="removeFilterItem(item.value)"
            class="ml-2 filter-item__remove"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2.00012C6.49 2.00012 2 6.49012 2 12.0001C2 17.5101 6.49 22.0001 12 22.0001C17.51 22.0001 22 17.5101 22 12.0001C22 6.49012 17.51 2.00012 12 2.00012ZM15.36 14.3001C15.65 14.5901 15.65 15.0701 15.36 15.3601C15.21 15.5101 15.02 15.5801 14.83 15.5801C14.64 15.5801 14.45 15.5101 14.3 15.3601L12 13.0601L9.7 15.3601C9.55 15.5101 9.36 15.5801 9.17 15.5801C8.98 15.5801 8.79 15.5101 8.64 15.3601C8.35 15.0701 8.35 14.5901 8.64 14.3001L10.94 12.0001L8.64 9.70012C8.35 9.41012 8.35 8.93012 8.64 8.64012C8.93 8.35012 9.41 8.35012 9.7 8.64012L12 10.9401L14.3 8.64012C14.59 8.35012 15.07 8.35012 15.36 8.64012C15.65 8.93012 15.65 9.41012 15.36 9.70012L13.06 12.0001L15.36 14.3001Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </div>
        <div
          class="filter__clear button button_empty-pink button_small"
          @click="clearFilter()"
        >
          Сбросить
          <span class="ml-2">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 3.50012L12.5 12.5001"
                stroke="currentColor"
                stroke-linecap="square"
              />
              <path
                d="M12.5 3.50012L3.5 12.5001"
                stroke="currentColor"
                stroke-linecap="square"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div class="personality-test__buttons d-flex mt-6">
      <div
      data-id="helperSave"
        class="button button_pink mr-3 px-6"
        :class="{ button_disabled: !currentDiseases.length }"
        @click="$emit('save', currentDiseases, currentNosologys, currentNosologysList)"
      >
        Сохранить
      </div>
      <div
        class="button button_gray px-6"
        @click="$emit('continueEv')"
        v-if="showContinue"
      >
        Пропустить
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/form-elements/Select.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PersonalityTest",
  components: {
    Select,
  },
  props: {
    showContinue: Boolean,
    showDiseases: Boolean
  },
  data: () => ({
    currentNosologys: [],
    disease: [],
  }),
  computed: {
    ...mapGetters(["Nosologys", "user"]),
    diseaseList() {
      let onlyCurrent = this.filteredNosologys.filter((el) =>
        this.currentNosologys.includes(el.id)
      );
      let diseaseList = [];
      if (onlyCurrent.length) {
        onlyCurrent.forEach((item) => {
          item.links
            .forEach((el) => {
              diseaseList.push(el);
            });
        });
      }
      let formattedList = diseaseList.map(function (el) {
        return {
          value: el.id,
          label: el.title,
        };
      });
      return {
        list: diseaseList,
        formattedList: formattedList,
      };
    },
    filteredNosologys() {
      return this.Nosologys.filter((el) => el.active);
    },
    currentDiseases() {
      return this.diseaseList.formattedList.filter((el) =>
        this.disease.includes(el.value)
      );
    },
    currentNosologysList() {
      return this.filteredNosologys.filter((el) =>
        this.currentNosologys.includes(el.id)
      ).map(el => ({id: el.id, title: el.title}));
    },
  },
  methods: {
    ...mapActions(["fetchNosologys"]),
    formattedImageList(item, tag) {
      return this.$root.host + item.images?.find((el) => el.tag === tag)?.image;
    },
    changeDisease(dis, state) {
      this.$emit('changeDisease', dis, state)
    },
    choiceItem(id, nosology) {
      let index = this.currentNosologys.indexOf(id);
      if (index !== -1) {
        this.currentNosologys.splice(index, 1);
      } else {
        this.currentNosologys.push(id);
        this.$emit('nosologyClick', nosology)
      }
    },
    removeFilterItem(val) {
      let index = this.disease.findIndex((el) => el === val);
      this.disease.splice(index, 1);
    },
    clearFilter() {
      this.disease = [];
    },
    selectAll() {
      if(this.disease.length === this.diseaseList.formattedList.length) {
        this.disease = []
        this.$emit('selectAll', 'Снять все')
      } else {
        this.disease = this.diseaseList.formattedList.map(el => el.value)
        this.$emit('selectAll', 'Выбрать все')
      }
      
    }
  },
  mounted() {
    this.fetchNosologys();
    if (this.user && Object.keys(this.user).length) {
      this.currentNosologys = this.user?.specialities?.map((el) => el.id) || [];
      this.disease = this.user?.diseases?.map((el) => el.id) || [];
    }
  },
  watch: {
    user() {
      if (this.user && Object.keys(this.user).length) {
        this.currentNosologys =
          this.user?.specialities?.map((el) => el.id) || [];
        this.disease = this.user?.diseases?.map((el) => el.id) || [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.personality-test {
  &__title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
    margin-bottom: 16px;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
    margin-bottom: 24px;
  }

  &__nosologys {
    display: grid;
    grid-template-columns: repeat(auto-fit, 176px);
    column-gap: 32px;
    row-gap: 32px;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
    }
  }
  &__nosology {
    position: relative;
    &-delete {
      position: absolute;
      top: -4px;
      right: -4px;
      background: #830051;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
    &_active {
      .personality-test {
        &__nosology {
          &-image-container {
            border-color: #830051;
          }
        }
      }
    }
    &-image-container {
      width: 176px;
      height: 100px;
      border: 3px solid transparent;
      background: radial-gradient(
        46.35% 35.31% at 51.82% 64.77%,
        #f4e8ce 0%,
        #d2d2d2 100%
      );
      border-radius: 10px;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: rgba(131, 0, 81, 0.09);
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    &-title {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
  }
}

.filter {
  &__current-filter-item {
    margin-top: 14px;
    display: flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-right: 18px;

    & svg {
      color: #d9dada;
      transition: 0.3s;
    }

    &:hover {
      & svg {
        color: #830051;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__current-filter {
    margin-top: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  &__clear {
    margin-top: 14px;
  }
}
</style>

<style>
.filter-item__remove {
  cursor: pointer;
}

.personality-test__select .selectaria__select {
  max-width: 592px;
}
</style>